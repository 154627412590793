import React from 'react'
import styled from 'styled-components'

const Content = () => {
  return (
    <ContentContainer>
    </ContentContainer>
  )
}

const ContentContainer = styled.main`
  background-color: rgb(252, 231, 0);
  height: 100vh;
  width: 100%;
`

export default Content